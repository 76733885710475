// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";

function sequence(options) {
  return Core__Array.reduce(options, [], (function (maybeAcc, opt) {
                return Core__Option.flatMap(maybeAcc, (function (acc) {
                              return Core__Option.map(opt, (function (x) {
                                            return acc.concat([x]);
                                          }));
                            }));
              }));
}

function sequence2(opts) {
  var val1 = opts[0];
  if (val1 === undefined) {
    return ;
  }
  var val2 = opts[1];
  if (val2 !== undefined) {
    return [
            Caml_option.valFromOption(val1),
            Caml_option.valFromOption(val2)
          ];
  }
  
}

function sequence3(opts) {
  var val1 = opts[0];
  if (val1 === undefined) {
    return ;
  }
  var val2 = opts[1];
  if (val2 === undefined) {
    return ;
  }
  var val3 = opts[2];
  if (val3 !== undefined) {
    return [
            Caml_option.valFromOption(val1),
            Caml_option.valFromOption(val2),
            Caml_option.valFromOption(val3)
          ];
  }
  
}

function sequence4(opts) {
  var val1 = opts[0];
  if (val1 === undefined) {
    return ;
  }
  var val2 = opts[1];
  if (val2 === undefined) {
    return ;
  }
  var val3 = opts[2];
  if (val3 === undefined) {
    return ;
  }
  var val4 = opts[3];
  if (val4 !== undefined) {
    return [
            Caml_option.valFromOption(val1),
            Caml_option.valFromOption(val2),
            Caml_option.valFromOption(val3),
            Caml_option.valFromOption(val4)
          ];
  }
  
}

function okOr(opt, err) {
  if (opt !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: "Error",
            _0: err
          };
  }
}

function orElse(opt, f) {
  if (opt !== undefined) {
    return opt;
  } else {
    return f();
  }
}

function tryMap(x, f) {
  return Core__Option.getOr(f(x), x);
}

function forNone(x, f) {
  if (x !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(x));
  } else {
    f();
    return ;
  }
}

function toArray(opt) {
  if (opt !== undefined) {
    return [Caml_option.valFromOption(opt)];
  } else {
    return [];
  }
}

export {
  sequence ,
  sequence2 ,
  sequence3 ,
  sequence4 ,
  okOr ,
  orElse ,
  tryMap ,
  forNone ,
  toArray ,
}
/* No side effect */
