// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/lib/es6/src/Future.bs.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function classList(classes) {
  return Core__Array.filterMap(classes, (function (param) {
                  if (param[1]) {
                    return param[0];
                  }
                  
                })).join(" ");
}

function renderOption(defaultOpt, opt, f) {
  var $$default = defaultOpt !== undefined ? Caml_option.valFromOption(defaultOpt) : null;
  if (opt !== undefined) {
    return f(Caml_option.valFromOption(opt));
  } else {
    return $$default;
  }
}

function memoState($$new, prev) {
  if (Caml_obj.equal($$new, prev)) {
    return prev;
  } else {
    return $$new;
  }
}

function ignoreCancel(_previous) {
  
}

function addCancel(original, $$new) {
  return (function () {
            $$new();
            Core__Option.forEach(original, (function (cancel) {
                    cancel();
                  }));
          });
}

function effects(effects$1) {
  var cancelFns = Core__Array.filterMap(effects$1, (function (x) {
          return x;
        }));
  if (cancelFns.length !== 0) {
    return (function () {
              cancelFns.forEach(function (fn) {
                    fn();
                  });
            });
  }
  
}

function futureEffect(future, callback) {
  Future.get(future, callback);
  return (function () {
            Future.cancel(future);
          });
}

function resultFutureEffect(future, onSuccess, onError) {
  return futureEffect(future, (function (res) {
                if (res.TAG === "Ok") {
                  return onSuccess(res._0);
                } else {
                  return onError(res._0);
                }
              }));
}

function usePeriodic(minTime, maxTime, cb, deps) {
  React.useEffect((function () {
          var cancel = {
            contents: undefined
          };
          var id = {
            contents: undefined
          };
          var time = {
            contents: minTime
          };
          var cancelled = {
            contents: false
          };
          var exec = function () {
            if (!cancelled.contents) {
              cancel.contents = cb(next);
              return ;
            }
            
          };
          var next = function (success) {
            if (success) {
              time.contents = minTime;
            } else {
              time.contents = Caml.int_min(maxTime, (time.contents << 1));
            }
            id.contents = Caml_option.some(setTimeout(exec, time.contents));
          };
          exec();
          return (function () {
                    cancelled.contents = true;
                    Core__Option.mapOr(id.contents, undefined, (function (prim) {
                            clearTimeout(prim);
                          }));
                    Core__Option.mapOr(cancel.contents, undefined, (function (x) {
                            x();
                          }));
                  });
        }), deps);
}

function Make(Params) {
  var context = React.createContext(Params.$$default);
  var useContext = function () {
    return React.useContext(context);
  };
  var make = context.Provider;
  var Provider = {
    make: make
  };
  return {
          context: context,
          useContext: useContext,
          Provider: Provider
        };
}

function MakeState(Params) {
  var context = React.createContext([
        Params.$$default,
        (function (param) {
            
          })
      ]);
  var useValue = function () {
    return React.useContext(context)[0];
  };
  var useSetter = function () {
    return React.useContext(context)[1];
  };
  var LumiReact$Context$MakeState$Provider = function (props) {
    var __initState = props.initState;
    var initState = __initState !== undefined ? __initState : (function () {
          return Params.$$default;
        });
    var match = React.useState(Curry.__1(initState));
    return React.createElement(context.Provider, {
                value: [
                  match[0],
                  match[1]
                ],
                children: props.children
              });
  };
  var Provider = {
    make: LumiReact$Context$MakeState$Provider
  };
  return {
          context: context,
          useValue: useValue,
          useSetter: useSetter,
          Provider: Provider
        };
}

var Context = {
  Make: Make,
  MakeState: MakeState
};

var context = React.createContext(new Date(0));

function useCurrentTime() {
  return React.useContext(context);
}

function LumiReact$CurrentTime$Provider(props) {
  var __refreshInterval = props.refreshInterval;
  var refreshInterval = __refreshInterval !== undefined ? __refreshInterval : 1000;
  var match = React.useState(function () {
        return new Date();
      });
  var setNow = match[1];
  var refreshIntervalMs = refreshInterval | 0;
  usePeriodic(refreshIntervalMs, refreshIntervalMs, (function (next) {
          setNow(function (param) {
                return new Date();
              });
          next(true);
        }), []);
  return React.createElement(context.Provider, {
              value: match[0],
              children: props.children
            });
}

var Provider = {
  make: LumiReact$CurrentTime$Provider
};

var CurrentTime = {
  context: context,
  useCurrentTime: useCurrentTime,
  Provider: Provider
};

function useEffectWithCallbackRef(useEffectHook, effect) {
  var domRef = React.useRef(undefined);
  var cleanupRef = React.useRef(undefined);
  var callbackDomRef = React.useCallback((function (element) {
          Core__Option.forEach(cleanupRef.current, (function (f) {
                  f();
                }));
          domRef.current = (element == null) ? undefined : Caml_option.some(element);
          var cleanup = effect(domRef.current);
          cleanupRef.current = cleanup;
        }), []);
  useEffectHook(function () {
        Core__Option.forEach(cleanupRef.current, (function (f) {
                f();
              }));
        cleanupRef.current = undefined;
        return effect(domRef.current);
      });
  React.useEffect((function () {
          return (function () {
                    Core__Option.forEach(cleanupRef.current, (function (f) {
                            f();
                          }));
                  });
        }), []);
  return callbackDomRef;
}

function filterMouseButton(button, handler, $$event) {
  if ($$event.button === button) {
    return handler($$event);
  }
  
}

function filterMouseButtons(mask, handler, $$event) {
  if (($$event.buttons & mask) !== 0) {
    return handler($$event);
  }
  
}

function $$setTimeout$1(fn, ms) {
  var id = setTimeout(fn, ms);
  return (function () {
            clearTimeout(id);
          });
}

function LumiReact$Dialog(props) {
  var setShowDialog = props.setShowDialog;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: "modal-background",
                      onClick: (function (param) {
                          setShowDialog(function (param) {
                                return false;
                              });
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: "modal-card"
                    }),
                JsxRuntime.jsx("button", {
                      className: "modal-close is-large",
                      onClick: (function (param) {
                          setShowDialog(function (param) {
                                return false;
                              });
                        })
                    })
              ],
              className: classList([
                    [
                      "modal",
                      true
                    ],
                    [
                      "is-active",
                      props.showDialog
                    ]
                  ])
            });
}

var Dialog = {
  make: LumiReact$Dialog
};

var noCancel;

export {
  classList ,
  renderOption ,
  memoState ,
  noCancel ,
  ignoreCancel ,
  addCancel ,
  effects ,
  futureEffect ,
  resultFutureEffect ,
  usePeriodic ,
  Context ,
  CurrentTime ,
  useEffectWithCallbackRef ,
  filterMouseButton ,
  filterMouseButtons ,
  $$setTimeout$1 as $$setTimeout,
  Dialog ,
}
/* context Not a pure module */
