// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiBox from "./LumiBox.bs.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as LumiResult from "./LumiResult.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";

function map(aCodec, to, from) {
  return {
          toString: (function (b) {
              return aCodec.toString(from(b));
            }),
          fromString: (function (s) {
              return Core__Option.flatMap(aCodec.fromString(s), to);
            })
        };
}

function box(codec) {
  return {
          toString: (function (x) {
              return codec.toString(x._0);
            }),
          fromString: (function (s) {
              return Core__Option.map(codec.fromString(s), LumiBox.box);
            })
        };
}

function string_toString(s) {
  return s;
}

function string_fromString(s) {
  return s;
}

var string = {
  toString: string_toString,
  fromString: string_fromString
};

function $$int(radix) {
  return {
          toString: (function (i) {
              return i.toString(radix);
            }),
          fromString: (function (s) {
              return Core__Int.fromString(radix, s);
            })
        };
}

function bool_toString(b) {
  if (b) {
    return "true";
  } else {
    return "false";
  }
}

function bool_fromString(s) {
  switch (s) {
    case "false" :
        return false;
    case "true" :
        return true;
    default:
      return ;
  }
}

var bool = {
  toString: bool_toString,
  fromString: bool_fromString
};

function option(codec) {
  var noneTag = "none";
  var someTag = "some-";
  return {
          toString: (function (opt) {
              return Core__Option.mapOr(opt._0, noneTag, (function (v) {
                            return someTag + codec.toString(v);
                          }));
            }),
          fromString: (function (s) {
              if (s === noneTag) {
                return {
                        TAG: "Box",
                        _0: undefined
                      };
              } else if (s.startsWith(someTag)) {
                return Core__Option.map(codec.fromString(s.slice(someTag.length)), (function (v) {
                              return {
                                      TAG: "Box",
                                      _0: Caml_option.some(v)
                                    };
                            }));
              } else {
                return ;
              }
            })
        };
}

function jzon(codec) {
  return {
          toString: (function (v) {
              return Jzon.encodeStringWith(v, codec);
            }),
          fromString: (function (s) {
              return LumiResult.ok(Jzon.decodeStringWith(s, codec));
            })
        };
}

export {
  map ,
  box ,
  string ,
  $$int ,
  bool ,
  option ,
  jzon ,
}
/* No side effect */
