// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation$LumiI18n from "./Translation.bs.mjs";

function translate(locale, entry) {
  switch (locale) {
    case "DAN" :
        switch (entry) {
          case "Cancel" :
              return "Ophæve";
          case "Close" :
              return "Tæt";
          case "Description" :
              return "Beskrivelse";
          case "LogIn" :
              return "Log ind";
          case "LogOut" :
              return "Log ud";
          case "Name" :
              return "Navn";
          case "Next" :
              return "Næste";
          case "Off" :
              return "Slukket";
          case "On" :
              return "På";
          case "Password" :
              return "Adgangskode";
          case "Port" :
              return "Port";
          case "Previous" :
              return "Forrige side";
          case "Reset" :
              return "Nulstil";
          case "Save" :
              return "Spare";
          case "Update" :
              return "Opdatering";
          case "Upload" :
              return "Upload";
          case "Username" :
              return "Brugernavn";
          
        }
    case "DEU" :
        switch (entry) {
          case "Cancel" :
              return "Abbrechen";
          case "Close" :
              return "Schließen";
          case "Description" :
              return "Beschreibung";
          case "LogIn" :
              return "Anmelden";
          case "LogOut" :
              return "Abmelden";
          case "Name" :
              return "Name";
          case "Next" :
              return "Nächste";
          case "Off" :
              return "Aus";
          case "On" :
              return "Ein";
          case "Password" :
              return "Passwort";
          case "Port" :
              return "Port";
          case "Previous" :
              return "Vorherige";
          case "Reset" :
              return "Zurücksetzen";
          case "Save" :
              return "Speichern";
          case "Update" :
              return "Aktualisieren";
          case "Upload" :
              return "Hochladen";
          case "Username" :
              return "Benutzername";
          
        }
    case "ENG" :
        switch (entry) {
          case "Cancel" :
              return "Cancel";
          case "Close" :
              return "Close";
          case "Description" :
              return "Description";
          case "LogIn" :
              return "Log in";
          case "LogOut" :
              return "Log out";
          case "Name" :
              return "Name";
          case "Next" :
              return "Next";
          case "Off" :
              return "Off";
          case "On" :
              return "On";
          case "Password" :
              return "Password";
          case "Port" :
              return "Port";
          case "Previous" :
              return "Previous";
          case "Reset" :
              return "Reset";
          case "Save" :
              return "Save";
          case "Update" :
              return "Update";
          case "Upload" :
              return "Upload";
          case "Username" :
              return "Username";
          
        }
    case "FRA" :
        switch (entry) {
          case "Cancel" :
              return "Annuler";
          case "Close" :
              return "Fermer";
          case "Description" :
              return "Description";
          case "LogIn" :
              return "Connexion";
          case "LogOut" :
              return "Déconnexion";
          case "Name" :
              return "Nom";
          case "Next" :
              return "Suivant";
          case "Off" :
              return "Désactivé";
          case "On" :
              return "Activé";
          case "Password" :
              return "Mot de passe";
          case "Port" :
              return "Port";
          case "Previous" :
              return "Précédent";
          case "Reset" :
              return "Réinitialiser";
          case "Save" :
              return "Enregistrer";
          case "Update" :
              return "Mettre à jour";
          case "Upload" :
              return "Télécharger";
          case "Username" :
              return "Nom d'utilisateur";
          
        }
    case "ITA" :
        switch (entry) {
          case "Cancel" :
              return "Annulla";
          case "Close" :
              return "Chiudi";
          case "Description" :
              return "Descrizione";
          case "LogIn" :
              return "Accedi";
          case "LogOut" :
              return "Esci";
          case "Name" :
              return "Nome";
          case "Next" :
              return "Avanti";
          case "Off" :
              return "Off";
          case "On" :
              return "Mer";
          case "Password" :
              return "Password";
          case "Port" :
              return "Porta";
          case "Previous" :
              return "Precedente";
          case "Reset" :
              return "Reimposta";
          case "Save" :
              return "Salva";
          case "Update" :
              return "Aggiorna";
          case "Upload" :
              return "Carica";
          case "Username" :
              return "Nome utente";
          
        }
    case "NLD" :
        switch (entry) {
          case "Cancel" :
              return "Annuleer";
          case "Close" :
              return "Sluiten";
          case "Description" :
              return "Beschrijving";
          case "LogIn" :
              return "Inloggen";
          case "LogOut" :
              return "Uitloggen";
          case "Name" :
              return "Naam";
          case "Next" :
              return "Volgende";
          case "Off" :
              return "Uit";
          case "On" :
              return "Aan";
          case "Password" :
              return "Wachtwoord";
          case "Port" :
              return "Poort";
          case "Previous" :
              return "Vorige";
          case "Reset" :
              return "Reset";
          case "Save" :
              return "Opslaan";
          case "Update" :
              return "Updaten";
          case "Upload" :
              return "Uploaden";
          case "Username" :
              return "Gebruikersnaam";
          
        }
    case "SPA" :
        switch (entry) {
          case "Cancel" :
              return "Cancelar";
          case "Close" :
              return "Cerrar";
          case "Description" :
              return "Descripción";
          case "LogIn" :
              return "Iniciar sesión";
          case "LogOut" :
              return "Cerrar sesión";
          case "Name" :
              return "Nombre";
          case "Next" :
              return "Siguiente";
          case "Off" :
              return "Desactivado";
          case "On" :
              return "Miércoles";
          case "Password" :
              return "Contraseña";
          case "Port" :
              return "Puerto";
          case "Previous" :
              return "Anterior";
          case "Reset" :
              return "Restablecer";
          case "Save" :
              return "Guardar";
          case "Update" :
              return "Actualizar";
          case "Upload" :
              return "Subir";
          case "Username" :
              return "Nombre de usuario";
          
        }
    case "SWE" :
        switch (entry) {
          case "Cancel" :
              return "Avboka";
          case "Close" :
              return "Nära";
          case "Description" :
              return "Beskrivning";
          case "LogIn" :
              return "Inloggning";
          case "LogOut" :
              return "Utloggning";
          case "Name" :
              return "Namn";
          case "Next" :
              return "Nästa";
          case "Off" :
              return "Av";
          case "On" :
              return "På";
          case "Password" :
              return "Lösenord";
          case "Port" :
              return "Hamn";
          case "Previous" :
              return "Tidigare";
          case "Reset" :
              return "Återställa";
          case "Save" :
              return "Spara";
          case "Update" :
              return "Uppdatera";
          case "Upload" :
              return "Ladda upp";
          case "Username" :
              return "Användarnamn";
          
        }
    
  }
}

var Provider = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

export {
  Provider ,
}
/* Provider Not a pure module */
