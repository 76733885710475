// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

function sequence(results) {
  return Core__Array.reduce(results, {
              TAG: "Ok",
              _0: []
            }, (function (maybeAcc, res) {
                return Core__Result.flatMap(maybeAcc, (function (acc) {
                              return Core__Result.map(res, (function (x) {
                                            return acc.concat([x]);
                                          }));
                            }));
              }));
}

function sequence2(param) {
  var res2 = param[1];
  var res1 = param[0];
  if (res1.TAG === "Ok") {
    if (res2.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                res1._0,
                res2._0
              ]
            };
    } else {
      return {
              TAG: "Error",
              _0: res2._0
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: res1._0
          };
  }
}

function sequence3(param) {
  var res3 = param[2];
  var res2 = param[1];
  var res1 = param[0];
  if (res1.TAG === "Ok") {
    if (res2.TAG === "Ok") {
      if (res3.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: [
                  res1._0,
                  res2._0,
                  res3._0
                ]
              };
      } else {
        return {
                TAG: "Error",
                _0: res3._0
              };
      }
    } else {
      return {
              TAG: "Error",
              _0: res2._0
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: res1._0
          };
  }
}

function sequence4(param) {
  var res4 = param[3];
  var res3 = param[2];
  var res2 = param[1];
  var res1 = param[0];
  if (res1.TAG === "Ok") {
    if (res2.TAG === "Ok") {
      if (res3.TAG === "Ok") {
        if (res4.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: [
                    res1._0,
                    res2._0,
                    res3._0,
                    res4._0
                  ]
                };
        } else {
          return {
                  TAG: "Error",
                  _0: res4._0
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: res3._0
              };
      }
    } else {
      return {
              TAG: "Error",
              _0: res2._0
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: res1._0
          };
  }
}

function sequence5(param) {
  var res5 = param[4];
  var res4 = param[3];
  var res3 = param[2];
  var res2 = param[1];
  var res1 = param[0];
  if (res1.TAG === "Ok") {
    if (res2.TAG === "Ok") {
      if (res3.TAG === "Ok") {
        if (res4.TAG === "Ok") {
          if (res5.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: [
                      res1._0,
                      res2._0,
                      res3._0,
                      res4._0,
                      res5._0
                    ]
                  };
          } else {
            return {
                    TAG: "Error",
                    _0: res5._0
                  };
          }
        } else {
          return {
                  TAG: "Error",
                  _0: res4._0
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: res3._0
              };
      }
    } else {
      return {
              TAG: "Error",
              _0: res2._0
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: res1._0
          };
  }
}

function ok(result) {
  if (result.TAG === "Ok") {
    return Caml_option.some(result._0);
  }
  
}

function traverseOption(opt, f) {
  if (opt !== undefined) {
    return Core__Result.map(f(Caml_option.valFromOption(opt)), (function (y) {
                  return Caml_option.some(y);
                }));
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

export {
  sequence ,
  sequence2 ,
  sequence3 ,
  sequence4 ,
  sequence5 ,
  ok ,
  traverseOption ,
}
/* No side effect */
