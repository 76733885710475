// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as LumiFetch from "lumi-rescript/lib/es6/src/LumiFetch.bs.mjs";
import * as V3$Linear from "lumi-linear/lib/es6/src/V3.bs.mjs";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

var jsonCodec = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "FixedIpAddress" ? "FixedIpAddress" : "AutomaticIpAddress";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "AutomaticIpAddress" :
                            return {
                                    TAG: "Ok",
                                    _0: "AutomaticIpAddress"
                                  };
                        case "FixedIpAddress" :
                            return {
                                    TAG: "Ok",
                                    _0: "FixedIpAddress"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var IpAddressMode = {
  jsonCodec: jsonCodec
};

var IpAddress = {
  jsonCodec: Jzon.string
};

var jsonCodec$1 = Jzon.object3((function (param) {
        return [
                param.free,
                param.occupied,
                param.error
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  free: param[0],
                  occupied: param[1],
                  error: param[2]
                }
              };
      }), Jzon.field("free", Jzon.$$int), Jzon.field("occupied", Jzon.$$int), Jzon.field("error", Jzon.$$int));

function add(a, b) {
  return {
          free: a.free + b.free | 0,
          occupied: a.occupied + b.occupied | 0,
          error: a.error + b.error | 0
        };
}

var SensorMeasurement_empty = {
  free: 0,
  occupied: 0,
  error: 0
};

var SensorMeasurement = {
  jsonCodec: jsonCodec$1,
  empty: SensorMeasurement_empty,
  add: add
};

function jsonCodec$2(thisCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.time,
                        param.this
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          time: param[0],
                          this: param[1]
                        }
                      };
              }), Jzon.field("when", LumiDate.DateTime.jsonCodec), Jzon.field("this", thisCodec));
}

function map(param, f) {
  return {
          time: param.time,
          this: f(param.this)
        };
}

async function decodeAtTime(response, f) {
  return Core__Result.map(await f(response), (function ($$this) {
                var time = Core__Result.getOr(LumiFetch.$$Headers.decodeString(response.headers, "Date", LumiDate.$$Date.fromString), new Date());
                return {
                        time: time,
                        this: $$this
                      };
              }));
}

function wrapAtTime(request) {
  return {
          url: request.url,
          init: request.init,
          decode: (function (response) {
              return decodeAtTime(response, request.decode);
            })
        };
}

var Fetch = {
  decodeAtTime: decodeAtTime,
  wrapAtTime: wrapAtTime
};

var AtTime = {
  jsonCodec: jsonCodec$2,
  map: map,
  Fetch: Fetch
};

function jsonCodec$3(errorCodec) {
  return Jzon.object2((function (param) {
                return [
                        param.first,
                        param.last
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          first: param[0],
                          last: param[1]
                        }
                      };
              }), Jzon.field("first", jsonCodec$2(errorCodec)), Jzon.field("last", jsonCodec$2(errorCodec)));
}

var ErrorRun = {
  jsonCodec: jsonCodec$3
};

function jsonCodec$4(errorCodec) {
  var errorRunCodec = jsonCodec$3(errorCodec);
  return LumiJzon.taggedVariant((function (value) {
                if (value.TAG === "OkSince") {
                  return [
                          "OkSince",
                          Jzon.encodeWith(value._0, LumiDate.DateTime.jsonCodec)
                        ];
                } else {
                  return [
                          "InError",
                          Jzon.encodeWith(value._0, errorRunCodec)
                        ];
                }
              }), (function (param) {
                var contents = param[1];
                var tag = param[0];
                switch (tag) {
                  case "InError" :
                      return Core__Result.map(Jzon.decodeWith(contents, errorRunCodec), (function (errorRun) {
                                    return {
                                            TAG: "InError",
                                            _0: errorRun
                                          };
                                  }));
                  case "OkSince" :
                      return Core__Result.map(Jzon.decodeWith(contents, LumiDate.DateTime.jsonCodec), (function (timestamp) {
                                    return {
                                            TAG: "OkSince",
                                            _0: timestamp
                                          };
                                  }));
                  default:
                    return {
                            TAG: "Error",
                            _0: {
                              NAME: "UnexpectedJsonValue",
                              VAL: [
                                [],
                                tag
                              ]
                            }
                          };
                }
              }));
}

function isOk(state) {
  if (state.TAG === "OkSince") {
    return true;
  } else {
    return false;
  }
}

function isError(state) {
  if (state.TAG === "OkSince") {
    return false;
  } else {
    return true;
  }
}

function errorRun(state) {
  if (state.TAG === "OkSince") {
    return ;
  } else {
    return state._0;
  }
}

var ErrorState = {
  jsonCodec: jsonCodec$4,
  isOk: isOk,
  isError: isError,
  errorRun: errorRun
};

var jsonCodec$5 = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "Http" ? "Http" : "Https";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "Cam_Http" :
                        case "Http" :
                            return {
                                    TAG: "Ok",
                                    _0: "Http"
                                  };
                        case "Cam_Https" :
                        case "Https" :
                            return {
                                    TAG: "Ok",
                                    _0: "Https"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var Protocol = {
  jsonCodec: jsonCodec$5
};

var jsonCodec$6 = Jzon.object3((function (param) {
        return [
                param.altitude,
                param.latitude,
                param.longitude
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  altitude: param[0],
                  latitude: param[1],
                  longitude: param[2]
                }
              };
      }), Jzon.field("altitude", Jzon.nullable(Jzon.$$float)), Jzon.field("latitude", Jzon.$$float), Jzon.field("longitude", Jzon.$$float));

function toGoogleMapsURL(param) {
  return "https://www.google.com/maps/search/?" + new URLSearchParams([
                [
                  "api",
                  "1"
                ],
                [
                  "query",
                  param.latitude.toString() + "," + param.longitude.toString()
                ]
              ]).toString();
}

var GeoPosition = {
  jsonCodec: jsonCodec$6,
  toGoogleMapsURL: toGoogleMapsURL
};

var jsonCodec$7 = Jzon.custom((function (openClosedState) {
        var tmp;
        tmp = openClosedState === "Open" ? "open" : "closed";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "closed" :
                            return {
                                    TAG: "Ok",
                                    _0: "Closed"
                                  };
                        case "open" :
                            return {
                                    TAG: "Ok",
                                    _0: "Open"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var OpenClosedState = {
  jsonCodec: jsonCodec$7
};

var jsonCodec$8 = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          if (v === "DontSync") {
            return [
                    "DontSync",
                    undefined
                  ];
          } else {
            return [
                    "SyncNow",
                    undefined
                  ];
          }
        } else {
          return [
                  "SyncAfter",
                  Jzon.encodeWith(v._0, LumiDate.DateTime.jsonCodec)
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "DontSync" :
              return {
                      TAG: "Ok",
                      _0: "DontSync"
                    };
          case "SyncAfter" :
              var stamp = pair[1];
              if (stamp !== undefined) {
                return Core__Result.map(Jzon.decodeWith(stamp, LumiDate.DateTime.jsonCodec), (function (x) {
                              return {
                                      TAG: "SyncAfter",
                                      _0: x
                                    };
                            }));
              } else {
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonValue",
                          VAL: [
                            [],
                            other
                          ]
                        }
                      };
              }
          case "SyncNow" :
              return {
                      TAG: "Ok",
                      _0: "SyncNow"
                    };
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        other
                      ]
                    }
                  };
        }
      }));

var SyncMode = {
  jsonCodec: jsonCodec$8
};

function fromString(str) {
  return Core__Int.fromString(16, str);
}

function toString(value) {
  return value.toString(16).padStart(2, "0");
}

var Channel = {
  fromString: fromString,
  toString: toString
};

function toString$1(param) {
  return toString(param.red) + toString(param.green) + toString(param.blue);
}

function fromString$1(str) {
  if (str.length === 6) {
    return Core__Option.map(LumiOption.sequence3(V3$Linear.map([
                        str.substring(0, 2),
                        str.substring(2, 4),
                        str.substring(4, 6)
                      ], fromString)), (function (param) {
                  return {
                          red: param[0],
                          green: param[1],
                          blue: param[2]
                        };
                }));
  }
  
}

function toCSS(color) {
  return "#" + toString$1(color);
}

var jsonCodec$9 = Jzon.custom((function (color) {
        return Jzon.encodeWith(toString$1(color), Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      return LumiOption.okOr(fromString$1(str), {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    str
                                  ]
                                });
                    }));
      }));

var Color_black = {
  red: 0,
  green: 0,
  blue: 0
};

var Color_white = {
  red: 255,
  green: 255,
  blue: 255
};

var Color_red = {
  red: 255,
  green: 0,
  blue: 0
};

var Color_green = {
  red: 0,
  green: 255,
  blue: 0
};

var Color_blue = {
  red: 0,
  green: 0,
  blue: 255
};

var Color_orange = {
  red: 255,
  green: 96,
  blue: 0
};

var Color = {
  Channel: Channel,
  toString: toString$1,
  fromString: fromString$1,
  toCSS: toCSS,
  jsonCodec: jsonCodec$9,
  black: Color_black,
  white: Color_white,
  red: Color_red,
  green: Color_green,
  blue: Color_blue,
  orange: Color_orange
};

var jsonCodec$10 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "CardinalDirectionNorth" :
              tmp = "North";
              break;
          case "CardinalDirectionNorthEast" :
              tmp = "NorthEast";
              break;
          case "CardinalDirectionEast" :
              tmp = "East";
              break;
          case "CardinalDirectionSouthEast" :
              tmp = "SouthEast";
              break;
          case "CardinalDirectionSouth" :
              tmp = "South";
              break;
          case "CardinalDirectionSouthWest" :
              tmp = "SouthWest";
              break;
          case "CardinalDirectionWest" :
              tmp = "West";
              break;
          case "CardinalDirectionNorthWest" :
              tmp = "NorthWest";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "East" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionEast"
                                  };
                        case "North" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionNorth"
                                  };
                        case "NorthEast" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionNorthEast"
                                  };
                        case "NorthWest" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionNorthWest"
                                  };
                        case "South" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionSouth"
                                  };
                        case "SouthEast" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionSouthEast"
                                  };
                        case "SouthWest" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionSouthWest"
                                  };
                        case "West" :
                            return {
                                    TAG: "Ok",
                                    _0: "CardinalDirectionWest"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var CardinalDirection = {
  jsonCodec: jsonCodec$10
};

export {
  IpAddressMode ,
  IpAddress ,
  SensorMeasurement ,
  AtTime ,
  ErrorRun ,
  ErrorState ,
  Protocol ,
  GeoPosition ,
  OpenClosedState ,
  SyncMode ,
  Color ,
  CardinalDirection ,
}
/* jsonCodec Not a pure module */
